import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LinkedIcon from "../../images/svgs/linkedin.svg"
import ArrowIcon from "../../images/svgs/play.svg"

const MainComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      kuba: file(relativePath: { eq: "kuba.jpg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)
  return (
    <div className="main">
      <div className="main-left">
        <img
          alt="Jakub Zboina andikitira"
          className="avatar"
          src={data.kuba.childImageSharp.original.src}
        />
      </div>
      <div className="main-right">
        <div className="info">
          <h1>Jakub Zboina</h1>
          <h2>Architekt platformy AI</h2>
          <h2>Comtegra S.A.</h2>
        </div>
        <div className="contact">
          <h1>jakub.zboina@gmail.com</h1>
          <a href="https://www.linkedin.com/in/jakubzboina/">
            <LinkedIcon className="icon" />
          </a>
        </div>
        <div className="skills">
          <h2>Cloud orchiestration</h2>
          <p>Kubernetes, Docker, Vmware </p>
          <h2>Programing</h2>
          <p>Python, JavaScript, SQL</p>
          <h2>Databases</h2>
          <p>MongoDB, ElasticSearch, Cassandra</p>
          <h2>BigData</h2>
          <p>Pandas, Rapids</p>
          <h2>ML, DL</h2>
          <p>Torch, Tf, Sklearn</p>
          <h2>Softs</h2>
          <p>
            Team lead,{" "}
            <a href="https://start.apps.cgc-waw-01.comtegra.cloud/">
              Product development
            </a>
            ,{" "}
            <a href="https://www.youtube.com/c/inteligentnerozmowy">Podcast</a>
          </p>
        </div>
        <ArrowIcon className="arrow-icon" />
      </div>
    </div>
  )
}

export default MainComponent
